









































import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

@Component
export default class DeleteOfferPopup extends Vue {
  @Prop() popupId!: string
  @Prop() brandOfferId!: number
  @Prop() brandId!: number

  deleteOffer () {
    if (this.brandOfferId === 0) {
      return false
    }
    this.$store.dispatch('deleteBrandOffer', { brandId: this.brandId, brandOfferId: this.brandOfferId }).then((response) => {
      if (response.status === 204) {
        let DeleteOfferButtonPopup: HTMLElement = this.$refs.DeleteOfferButtonPopup as HTMLElement
        DeleteOfferButtonPopup.click()
        this.$emit('brandOfferDeleted')
      }
    }, (response) => {

    })
  }
}
