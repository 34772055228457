var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: _vm.popupId,
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "myLargeModalLabel",
        "aria-hidden": "true"
      }
    },
    [
      _c("div", { staticClass: "modal-dialog modal-dialog-centered" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _c("h2", [_vm._v("WARNING?")]),
            _c("button", {
              ref: "DeleteOfferButtonPopup",
              staticClass: "close",
              attrs: {
                type: "button",
                "data-dismiss": "modal",
                "aria-label": "Close"
              }
            })
          ]),
          _c("div", { staticClass: "modal-body" }, [
            _vm._m(0),
            _c("div", { staticClass: "row" }, [
              _vm._m(1),
              _c("div", { staticClass: "col-xs-6" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn-lg-green",
                    on: { click: _vm.deleteOffer }
                  },
                  [_vm._v("YES")]
                )
              ])
            ])
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("label", [
        _vm._v(
          "\n            Are you sure you want to delete this Offer for brand?\n          "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xs-6" }, [
      _c(
        "button",
        {
          staticClass: "btn-lg-grey",
          attrs: { "data-dismiss": "modal", "aria-label": "Close" }
        },
        [_vm._v("NO")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }