<template>
  <div
    :id="popupId"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myLargeModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h2 style="text-align:center;">Send Custom Notifications</h2><br/>
              <button
                ref="popupCloseButton"
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
          </div>
        <div class="modal-body">
          <form data-vv-scope="send-email" @submit.prevent="sendEmailToPicklrs">
            <select
              @change="getPicklStatus"
              class="form-control col-sm-6"
              v-model="picklType"
              name="pickl-type"
              :class="{'input': true, 'is-danger': errors.has('send-email.pickl-type') }"
            >
              <option :value="'NON_GEO'">Go-Anywhere</option>
              <option :value="'GEO'">Geo-Located</option>
            </select>
            <select
              class="form-control col-sm-6"
              v-model="type"
              name="type"
              :class="{'input': true, 'is-danger': errors.has('send-email.type') }"
              :disabled="picklType=='NON_GEO'"
            >
              <option :value="'ACCEPTED'">ACCEPTED</option>
              <option :value="'PENDING'">LAUNCHED</option>
            </select>
            <div class="in-padd">
              <h3>Select Notification Type</h3>
              <ul class="nav nav-tabs" role="tablist">
                <li role="presentation" class="active" >
                  <a href="#email-form" aria-controls="pickl" role="tab" data-toggle="tab" @click="setCustomNotificationTypeValue('email')">
                  EMAIL
                  </a>
                </li>
                <li role="presentation">
                  <a href="#message-form" aria-controls="pickl" role="tab" data-toggle="tab" @click="setCustomNotificationTypeValue('message')">
                    MESSAGE
                  </a>
                </li>
                <li role="presentation">
                  <a href="#notification-form" aria-controls="insights" role="tab" data-toggle="tab" @click="setCustomNotificationTypeValue('notification')">
                    NOTIFICATION
                  </a>
                </li>
              </ul>
              <div class="tab-content">
                <div role="tabpanel" class="tab-pane fade in active" id="email-form">
                  <br/>
                  <input placeholder="Enter Email Subject" type="text" v-model="emailSubject"
                  :class="{ 'input': true, 'is-danger': errors.has('send-email.subject') }" />

                  <VueEditor
                  use-custom-image-handler  @image-added="handleImageAdded"
                    v-model="emailContent" />
                </div>
                <div role="tabpanel" class="tab-pane fade in" id="message-form">
                  <br/>
                  <textarea placeholder="Enter Message content" v-model="smsBody" rows="3"></textarea>
                </div>
                <div role="tabpanel" class="tab-pane fade in" id="notification-form">
                  <br/>
                  <input v-model="notificationTitle" type="text" placeholder="Enter Push Notification Title" />
                  <textarea v-model="notificationBody" rows="2" placeholder="Enter Push Notification Body"></textarea>
                </div>
              </div>
            </div>
            <input
              type="submit"
              :value="submitButtonText"
              class="btn-lg-green"
              :disabled="submittingForm"
            />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { VueEditor, Quill } from 'vue2-editor'
export default {
  components: {
    VueEditor
  },
  props: ['popupId', 'brandId'],
  data () {
    return {
      customNotificationType: 'email',
      smsBody: '',
      notificationTitle: '',
      notificationBody: '',
      type: 'ACCEPTED',
      picklType: 'GEO',
      emailSubject: '',
      emailContent: '',
      submittingForm: false,
      submitButtonText: 'SEND MAIL'
    }
  },
  name: 'SendCustomMixNotificationPopup',
  methods: {
    getPicklStatus () {
      if (this.picklType === 'NON_GEO') {
        this.type = 'PENDING'
      }
    },

    setCustomNotificationTypeValue (type) {
      this.customNotificationType = type
      this.submitButtonText = 'SEND ' + type.toUpperCase()
    },
    sendEmailToPicklrs () {
      let payload = { 'picklType': this.picklType, 'status': this.type, 'notificationType': this.customNotificationType, brandId: this.brandId }

      if (this.customNotificationType === 'email') {
        console.log(this.emailContent)
        if (this.emailSubject === '' || this.emailContent === '') {
          this.$notify({ title: 'Please enter subject and content of email', type: 'error' })
          return false
        } else {
          payload.emailContent = this.emailContent
          payload.emailSubject = this.emailSubject
        }
      }
      if (this.customNotificationType === 'message') {
        if (this.smsBody === '') {
          this.$notify({ title: 'Please enter sms content', type: 'error' })
          return false
        } else {
          payload.smsBody = this.smsBody
        }
      }

      if (this.customNotificationType === 'notification') {
        if (this.notificationBody === '' || this.notificationTitle === '') {
          this.$notify({ title: 'Please enter title and body of notification', type: 'error' })
          return false
        } else {
          payload.notificationBody = this.notificationBody
          payload.notificationTitle = this.notificationTitle
        }
      }
      this.submittingForm = true
      this.$store.dispatch('sendCustomMixNotifications', payload).then((response) => {
        console.log(response)
        this.submittingForm = false
        this.$notify({ title: this.customNotificationType + ' sent to picklrs! Try sending another notifications', type: 'success' })
      }, error => {
        this.$notify({ title: error.body.error, type: 'error' })
        this.submittingForm = false
      })
    },

    handleImageAdded (file, Editor, cursorLocation, resetUploader) {
      var formData = new FormData()
      formData.append('image', file)
      this.$http
        .post('email-media/upload', formData)
        .then((response) => {
          let url = response.body // Get url from response
          Editor.insertEmbed(cursorLocation, 'image', url)
          resetUploader()
        }, response => {
        })
    }
  }
}
</script>
<style scoped>
p {
  color: #232323;
  font-size: 24px;
  text-align: center;
}
.btn-negative {
  font-size: 15px;
  font-weight: 400;
  margin-top: 0;
}

.image-upload {
  display: inline-block;
  width: auto;
  text-align: center;
}
select,
select:hover {
  border: 1px solid #6f3053;
  border-radius: 3px;
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 20px;
  color: black;
  width: 100%;
  padding: 14px 42px 14px 16px;
  height: 50px;
  background-position: right 11px center;
  background-size: 20px 12px;
  background-image: url("/img/Create Pickl/selectproductDropdown_icon.png");
  background-color: white;
}

option {
  color: black;
  background-color: white;
}
h3 {
  text-align: center;
}
</style>
