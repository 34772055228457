





















import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

@Component
export default class DeactivateBrandPopup extends Vue {
    @Prop() popupId!: string
    @Prop() brandId!: string
    public payload = {
      credits: '',
      notes: ''
    }

    addFreePickls () {
      this.$validator.validateAll('free-pickls').then((result) => {
        if (result) {
          let popupCloseButton: HTMLElement = this.$refs.popupCloseButton as HTMLElement
          this.$store.dispatch('addFreePickls', { id: this.brandId, data: this.payload }).then(() => {
            this.$notify({ text: `${this.payload.credits} pickls added to brand`, type: 'success' })
            Object.assign(this.$data, (this.$options.data as any).apply(this))
            this.$nextTick()
              .then(() => {
                this.$validator.errors.clear('free-pickls')
              })
            this.$emit('freePicklsAdded')
            popupCloseButton.click()
          }, response => this.$notify({ text: 'Cannot add pickls', type: 'error' }))
        } else if (this.$validator.errors.has('free-pickls.No of Pickls')) {
          this.$notify({ text: this.$validator.errors.first('free-pickls.No of Pickls'), type: 'error' })
        }
      })
    }
}
