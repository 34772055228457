var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: _vm.popupId,
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "myLargeModalLabel",
        "aria-hidden": "true"
      }
    },
    [
      _vm.brandId != undefined
        ? _c("div", { staticClass: "modal-dialog modal-dialog-centered" }, [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-header" }, [
                _vm._m(0),
                _c("button", {
                  ref: "popupCloseButton",
                  staticClass: "close",
                  attrs: {
                    type: "button",
                    "data-dismiss": "modal",
                    "aria-label": "Close"
                  }
                })
              ]),
              _c("div", { staticClass: "modal-body" }, [
                _c(
                  "form",
                  {
                    attrs: { "data-vv-scope": "free-pickls" },
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.addFreePickls($event)
                      }
                    }
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.payload.credits,
                          expression: "payload.credits"
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|integer|max_value:25",
                          expression: "'required|integer|max_value:25'"
                        }
                      ],
                      class: {
                        input: true,
                        "is-danger": _vm.errors.has("free-pickls.No of Pickls")
                      },
                      attrs: {
                        type: "text",
                        placeholder: "No of Pickls",
                        name: "No of Pickls"
                      },
                      domProps: { value: _vm.payload.credits },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.payload, "credits", $event.target.value)
                        }
                      }
                    }),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.payload.notes,
                          expression: "payload.notes"
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'"
                        }
                      ],
                      class: {
                        input: true,
                        "is-danger": _vm.errors.has("free-pickls.Notes")
                      },
                      attrs: {
                        maxlength: "100",
                        placeholder: "Notes",
                        name: "Notes"
                      },
                      domProps: { value: _vm.payload.notes },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.payload, "notes", $event.target.value)
                        }
                      }
                    }),
                    _c("input", {
                      staticClass: "btn-lg-green",
                      attrs: { type: "submit", value: "SAVE" }
                    })
                  ]
                )
              ])
            ])
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", [
      _c("img", { attrs: { src: "/img/Alerts/plusbig_icon.png" } }),
      _vm._v(" Free Pickls")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }