

























import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'
import { Brand } from '@/components/SAdmin/BrandItem.vue'

@Component
export default class DeactivateBrandPopup extends Vue {
    @Prop() popupId!: string;
    @Prop() brand!: Brand;

    changeStatus () {
      let action = this.brand.status === 'INACTIVE' ? 'activate' : 'deactivate'
      let popupCloseButton: HTMLElement = this.$refs.popupCloseButton as HTMLElement
      this.$store.dispatch('editBrand', { id: this.brand.id, action: action }).then(() => {
        this.$emit('statusChanged')
        popupCloseButton.click()
      }, () => popupCloseButton.click())
    }
}
