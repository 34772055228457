























































import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'
import DatePicker from 'vue2-datepicker'

@Component({
  components: {
    DatePicker
  }
})
export default class DeactivateBrandPopup extends Vue {
  @Prop() popupId!: string
  @Prop() brandId!: number
  public date: Date = new Date()
  public submittingForm = false
  public submitButtonText = 'DELETE'

  notBeforeToday (date: any) {
    return date < new Date()
  }
  sendDeleteExpiredPicklRequest () {
    this.$validator.validateAll('delete-expired-pickls-form').then(result => {
      if (result) {
        this.submitButtonText = 'SENDING REQUEST'
        this.submittingForm = true
        let closeExtendExpirationPopup: HTMLElement = this.$refs.closeExtendExpirationPopup as HTMLElement
        let stringDate
        let stringExtendDate
        if (this.date) {
          stringDate = this.date.getFullYear() + '-' + (this.date.getMonth() + 1) + '-' + this.date.getDate()
        }
        let payload = { 'brandId': this.brandId, date: stringDate, extendDate: stringExtendDate }
        this.$store.dispatch('deleteExpiredPickls', payload).then((response) => {
          this.submittingForm = false
          this.$notify({ text: response.body.message, type: 'success' })
          this.$emit('deletedExpiredPickls')
          Object.assign(this.$data, (this.$options.data as any).apply(this))
          this.$nextTick()
            .then(() => {
              this.$validator.errors.clear('delete-expired-pickls-form')
            })
          closeExtendExpirationPopup.click()
          this.date = new Date()
        },
        (response) => {
          this.$notify({ text: 'Can not delete expirted pickls', type: 'error' })
        }
        ).finally(() => {
          this.submittingForm = false
          this.submitButtonText = 'DELETE'
        })
      } else if (this.$validator.errors.has('delete-expired-pickls-form.date')) {
        this.submittingForm = false
        this.$notify({ text: this.$validator.errors.first('delete-expired-pickls-form.date'), type: 'error' })
      }
    })
  }
}
