var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: _vm.popupId,
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "myLargeModalLabel",
        "aria-hidden": "true"
      }
    },
    [
      _vm.brandId != undefined
        ? _c("div", { staticClass: "modal-dialog modal-dialog-centered" }, [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-header" }, [
                _c("h2", [_vm._v("Update Stripe Fee")]),
                _c("button", {
                  ref: "popupCloseButton",
                  staticClass: "close",
                  attrs: {
                    type: "button",
                    "data-dismiss": "modal",
                    "aria-label": "Close"
                  }
                })
              ]),
              _c("div", { staticClass: "modal-body" }, [
                _c(
                  "form",
                  {
                    attrs: { "data-vv-scope": "update-stripe-fee-form" },
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.updateStripeFee($event)
                      }
                    }
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.payload.stripe_fee,
                          expression: "payload.stripe_fee"
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|integer|max_value:25",
                          expression: "'required|integer|max_value:25'"
                        }
                      ],
                      class: {
                        input: true,
                        "is-danger": _vm.errors.has(
                          "update-stripe-fee-form.stripe-fee"
                        )
                      },
                      attrs: {
                        type: "text",
                        placeholder: "Set New Stripe Fee",
                        name: "stripe-fee"
                      },
                      domProps: { value: _vm.payload.stripe_fee },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.payload,
                            "stripe_fee",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _c("input", {
                      staticClass: "btn-lg-green",
                      attrs: { type: "submit", value: "UPDATE" }
                    })
                  ]
                )
              ])
            ])
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }