





































































import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'
import DatePicker from 'vue2-datepicker'

@Component({
  components: {
    DatePicker
  }
})
export default class DeactivateBrandPopup extends Vue {
  @Prop() popupId!: string
  @Prop() brandId!: number
  public date: Date = new Date()
  public extendDate: Date = new Date()
  public submittingForm = false
  public submitButtonText = 'EXTEND EXPIRATION'

  notBeforeToday (date: any) {
    return date < new Date()
  }
  sendExtendExpirationRequest () {
    this.$validator.validateAll('extend-expiration-request').then(result => {
      if (result) {
        this.submitButtonText = 'SENDING REQUEST'
        this.submittingForm = true
        let closeExtendExpirationPopup: HTMLElement = this.$refs.closeExtendExpirationPopup as HTMLElement
        let stringDate
        let stringExtendDate
        if (this.date) {
          stringDate = this.date.getFullYear() + '-' + (this.date.getMonth() + 1) + '-' + this.date.getDate()
        }
        if (this.extendDate) {
          stringExtendDate = this.extendDate.getFullYear() + '-' + (this.extendDate.getMonth() + 1) + '-' + this.extendDate.getDate()
        }
        let payload = { 'brandId': this.brandId, date: stringDate, extendDate: stringExtendDate }
        this.$store.dispatch('extendPicklExpiration', payload).then((response) => {
          this.submittingForm = false
          this.$notify({ text: response.body.message, type: 'success' })
          this.$emit('expirationExtended', 'Expiration date extended successfully')
          Object.assign(this.$data, (this.$options.data as any).apply(this))
          this.$nextTick()
            .then(() => {
              this.$validator.errors.clear('extend-expiration-request')
            })
          closeExtendExpirationPopup.click()
          this.submittingForm = false
          this.submitButtonText = 'EXTEND EXPIRATION'
          this.date = new Date()
          this.extendDate = new Date()
        }, response => this.$notify({ text: 'Can not Extend Date', type: 'error' }))
      } else if (this.$validator.errors.has('extend-expiration-request.date')) {
        this.$notify({ text: this.$validator.errors.first('extend-expiration-request.date'), type: 'error' })
      }
    })
  }
}
