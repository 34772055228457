







































































































































































































































































































































































































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { Brand } from '@/components/SAdmin/BrandItem.vue'
import DeactivateBrandPopup from '@/components/SAdmin/DeactivateBrandPopup.vue'
import HollowCard from '@/components/common/HollowCard.vue'
import PicklItem from '@/components/common/PicklItem.vue'
import ChangePasswordPopup from '@/components/common/ChangePasswordPopup.vue'
import FreePicklsPopup from '@/components/SAdmin/FreePicklsPopup.vue'
import AssignOfferPopup from '@/components/SAdmin/AssignOfferPopup.vue'
import PicklDetails from '@/components/common/PicklDetails.vue'
import SendNotification from '@/components/SAdmin/SendNotification.vue'
import DeletePicklPopup from '@/components/SAdmin/DeletePicklPopup.vue'
import ExtendExpiration from '@/components/SAdmin/ExtendExpiration.vue'
import CancelBrandSubscription from '@/components/SAdmin/CancelBrandSubscription.vue'
import DeleteExpiredPickls from '@/components/SAdmin/DeleteExpiredPickls.vue'
import DeleteBrandOfferPopup from '@/components/SAdmin/DeleteBrandOfferPopup.vue'
import SurveyItem from '@/components/SAdmin/SurveyItem.vue'
import DeleteSurveyPopup from '@/components/common/DeleteSurveyPopup.vue'
import ChoosePicklTypePopup from '@/components/common/ChoosePicklTypePopup.vue'
import NonGeoPicklItem from '@/components/common/NonGeoPicklItem.vue'
import UpdateStripeFeePopup from '@/components/SAdmin/UpdateStripeFeePopup.vue'
import ExpiredPicklPopup from '@/components/SAdmin/ExpiredPicklsPopup.vue'
import SendCustomMixNotificationPopup from '@/components/SAdmin/SendCustomMixNotificationlPopup.vue'

@Component({
  components: {
    DeactivateBrandPopup,
    HollowCard,
    PicklItem,
    ChangePasswordPopup,
    FreePicklsPopup,
    PicklDetails,
    SendNotification,
    DeletePicklPopup,
    ExtendExpiration,
    CancelBrandSubscription,
    DeleteExpiredPickls,
    AssignOfferPopup,
    DeleteBrandOfferPopup,
    SurveyItem,
    DeleteSurveyPopup,
    ChoosePicklTypePopup,
    NonGeoPicklItem,
    UpdateStripeFeePopup,
    ExpiredPicklPopup,
    SendCustomMixNotificationPopup
  }
})
export default class BrandDetails extends Vue {
  public brand: any = {};
  public picklIndex = 0;
  public nonGeoPicklIndex = 0;
  public status: { status: string; count: number }[] = [];
  public pickls: any = [];
  public nonGeoPickls:any = []
  public store: string = '';
  public offset: number = 0;
  public limit: number = 100;
  public LoginHistoryOffset: number = 0;
  public LoginHistoryLimit: number = 100;
  public totalCount: number = 0;
  public activePickl = null
  public loadingPickls: boolean = true;
  public userRole = 'staff'
  public brandLoginHistory: any = [];
  public loadingLoginHistory: boolean = true;
  public noMoreLoginHistory: boolean = true;
  public selectedPicklId: number = 0
  public deletedPickls: any[] = []

  public storeLocations:any[] =[]
  public stores:any[] = []
  public storeName:any = ''
  public locationId:any = ''
  public storeLocationId:any = ''
  public stateId:any = ''
  public storeLimitPerPage:number = 1000
  public storeOffset:number = 0
  public storeLocationLimitPerPage:number = 1000
  public storeLocationOffset:number = 0
  public states:any[] = []
  public timer:number = 0
  public hasMorePickls: boolean = false
  public userUrl: string = 'staff'
  public countries:any[] = []
  public countryId:any = ''
  public offers:any = []
  public brandOffers:any = []
  public selectedBrandOfferId:number = 0
  public busy: boolean = false
  public surveyQuery: string = ''
  public surveys:any[] = []
  public brandId = 0
  public actionSurvey: any = null
  public statuses: any[] = []
  public active: number = 0
  public limitPerPage: number = 100
  public rowsOffset: number = 0
  public sort:any = 'expire_date_time-ASC'

  get activationDate () {
    let date = '1970-01-01 12:00:00'
    if (this.brand.subscription_history.lenght > 0) {
      date = this.brand.subscription_history[this.brand.subscription_history.length - 1]['created_at']
    } else {
      date = this.brand.current_subscription.created_at
    }
    return date
  }

  redirectToCreateSurveyPage () {
    this.$router.push('/sadmin/survey/create?filldraft=true')
  }
  showDataVisualsPage () {
    this.$router.push('/' + this.userUrl + '/brands/' + this.brand.id + '/data-visuals')
  }
  getNonGeoPicklStatusCount () {
    this.loadingPickls = true
    this.$http
      .get('pickls/status-count?type=NON_GEO')
      .then((response:any) => {
        this.statuses = response.body.data
        this.loadingPickls = false
        let status = this.$route.query['status']
        if (status) {
          for (let i = 0; i < this.statuses.length; ++i) {
            if (this.statuses[i].status === status) {
              this.nonGeoPicklIndex = i
              break
            }
          }
        }
        this.getNonGeoPickls()
      }, response => {
        this.loadingPickls = false
      })
  }

  getNonGeoPickls () {
    this.loadingPickls = true
    let params = { 'limit': this.limitPerPage, 'offset': this.rowsOffset, 'status': this.statuses[this.nonGeoPicklIndex].status, 'brandId': this.brandId, countryId: this.countryId, 'stateId': this.stateId, 'sort': this.sort, 'type': 'NON_GEO' }
    this.$http
      .get('pickls', { params: params })
      .then((response: any) => {
        if (this.limitPerPage === params.limit && this.rowsOffset === params.offset && params.status === this.statuses[this.nonGeoPicklIndex].status && this.brandId === params.brandId) {
          this.rowsOffset += this.limitPerPage
          this.nonGeoPickls.push(...response.body.data)
          if (this.nonGeoPickls.length === response.body.count) {
            this.hasMorePickls = false
          } else {
            this.hasMorePickls = true
          }
          this.loadingPickls = false
        }
      }, response => {
        this.loadingPickls = false
      })
    this.$router.push({ query: { status: this.statuses[this.nonGeoPicklIndex].status } })
  }

  getSurveys (clear = true) {
    this.busy = true
    this.$store.dispatch('getSurveysList', { query: this.surveyQuery, limit: this.limit, offset: this.offset, brand_id: this.$route.params.id }).then((response) => {
      if (response.options.query === this.surveyQuery && response.options.limit === this.limit && this.offset === response.options.offset) {
        if (clear) this.surveys = []
        this.surveys.push(...response.response.body.surveys)
        this.totalCount = response.response.body.count
        this.busy = false
      }
    }, (response) => {
      this.busy = false
    })
  }
  get hasLoadMore (): boolean {
    return this.surveys.length < this.totalCount
  }

  surveyRefresh () {
    this.offset = 0
    this.getSurveys()
  }

  UpdatePicklList (): void {
    this.pickls = []
    this.offset = 0
    this.getBrand()
    this.getPickls()
  }

  getCountries () {
    this.$http.get('countries?service_enabled=true').then((response:any) => {
      this.countries = response.body.data
    })
  }

  setActiveBrandOfferId (brandOfferId: number) {
    this.selectedBrandOfferId = brandOfferId
  }
  getOffers () {
    this.$store.dispatch('getOffersList', { limit: 1000, offset: 0 }).then((response) => {
      this.offers.push(...response.response.body.offers)
      this.totalCount = response.response.body.count
    }, (response) => {
    })
  }

  getBrandOffers () {
    this.$store.dispatch('getBrandOffersList', { brandId: this.$route.params.id }).then((response) => {
      this.brandOffers.push(...response.response.body.brandOffers)
    })
  }

  updateBrandOffersList () {
    this.brandOffers = []
    this.getBrandOffers()
  }

  getBrand (): void {
    this.$store.dispatch('getBrand', this.$route.params.id).then(brand => {
      this.brand = brand
      this.$store.commit('setBrandCredits', this.brand.credits)
    })
  }
  pushToPicklDetailsPage (picklId: any) {
    this.$router.push('/' + this.userUrl + '/pickls/' + picklId)
  }

  pushToNonGeoPicklDetailsPage (picklId: any) {
    this.$router.push('/' + this.userUrl + '/non-geo-pickl/' + picklId)
  }
  getPicklsCount (): void {
    this.loadingPickls = true
    this.$store.dispatch('getBrandStatusCount', { brandId: this.brand.id }).then(
      (response: any) => {
        this.status = response.body.data
        this.loadingPickls = false
        this.getPickls()
      },
      response => {
        this.loadingPickls = false
      }
    )
  }

  getPickls () {
    let sort = Vue.common.statusSortOrder[this.status[this.picklIndex].status]
    this.loadingPickls = true
    let params = { 'limit': this.limit, 'offset': this.offset, 'status': this.status[this.picklIndex].status, 'storeLocationId': this.storeLocationId, 'brandId': this.brand.id, 'stateId': this.stateId, countryId: this.countryId, 'sort': sort }
    this.$http
      .get('pickls', { params: params })
      .then((response:any) => {
        if (this.limit === params.limit && this.offset === params.offset && params.status === this.status[this.picklIndex].status && this.storeLocationId === params.storeLocationId && this.brand.id === params.brandId) {
          this.offset += this.limit
          this.pickls.push(...response.body.data)
          if (this.pickls.length === response.body.count) {
            this.hasMorePickls = false
          } else {
            this.hasMorePickls = true
          }
          this.loadingPickls = false
        }
      }, response => {
        this.loadingPickls = false
      })
    this.$router.push({ query: { status: this.status[this.picklIndex].status } })
  }

  // getPickls (clear: boolean = true): void {
  //   this.loadingPickls = true
  //   let sort = Vue.common.statusSortOrder[this.status[this.picklIndex].status]
  //   this.$store
  //     .dispatch('getBrandPickls', {
  //       id: this.brand.id,
  //       status: this.status[this.picklIndex].status,
  //       storeName: this.store,
  //       limit: this.limit,
  //       offset: this.offset,
  //       sort: sort
  //     })
  //     .then(
  //       (response: any) => {
  //         if (
  //           response.options.storeName === this.store &&
  //           response.options.status === this.status[this.picklIndex].status &&
  //           this.offset === response.options.offset
  //         ) {
  //           if (clear) this.pickls = []
  //           this.pickls.push(...response.response.body.data)
  //           this.totalCount = response.response.body.count
  //           this.loadingPickls = false
  //         }
  //       },
  //       response => {
  //         this.loadingPickls = false
  //       }
  //     )
  // }

  getBrandLoginHistory () {
    this.loadingLoginHistory = true
    this.$store.dispatch('getBrandLoginHistory', { brand_id: this.$route.params.id, limit: this.LoginHistoryLimit, offset: this.LoginHistoryOffset }).then(
      (response: any) => {
        this.loadingLoginHistory = false
        this.brandLoginHistory.push(...response.data.data)
        this.noMoreLoginHistory = this.brandLoginHistory.length === response.data.count
        this.LoginHistoryOffset += this.LoginHistoryLimit
      },
      response => {
        this.loadingPickls = false
      }
    )
  }

  newPassword (password: string, confirm: string): void {
    this.$store.dispatch('updatePassword', {
      id: this.brand.id,
      newp: password,
      confirm: confirm
    })
  }
  getStores (clear: boolean = true): void {
    this.$store.dispatch('getStores', { limit: this.storeLimitPerPage, offset: this.storeOffset, sort: 'name-ASC' }).then((response: any) => {
      this.stores.push(...response.response.body.data)
      this.storeOffset += this.storeLimitPerPage
      if (this.storeOffset < response.response.body.count) {
        this.getStores()
      }
    })
  }
  getStoreLocations (storeId:any, reset:boolean = true) {
    this.locationId = ''
    this.storeLocationId = ''
    if (storeId === '') {
      this.updateList()
      this.storeLocations = []

      return false
    }
    if (reset) {
      this.storeLocations = []
      this.storeLocationLimitPerPage = 1000
      this.storeLocationOffset = 0
    }
    let q = { sort: 'id-ASC', storeId: storeId, 'limit': this.storeLocationLimitPerPage, offset: this.storeLocationOffset }
    this.$http
      .get('store_locations', { params: q })
      .then((response: any) => {
        if (this.storeLocationLimitPerPage === q.limit && this.storeLocationOffset === q.offset) {
          this.storeLocationOffset += this.storeLocationLimitPerPage
          this.storeLocations.push(...response.body.data)
          if (this.storeLocationOffset < response.body.count) {
            this.getStoreLocations(storeId, false)
          } else {
            this.updateStoreLocationId(null)
          }
        }
      }
      )
  }
  clearList () {
    this.pickls = []
    this.nonGeoPickls = []
    this.offset = 0
    this.rowsOffset = 0
  }
  updateStoreLocationId (storeLocationId: any) {
    if (this.locationId === '') {
      this.storeLocationId = this.storeLocations.map(location => location.id).join(',')
    } else {
      this.storeLocationId = this.locationId
    }
    if (this.storeLocations.length > 0) {
      this.updateList()
    } else {
      this.clearList()
    }
  }

  updateList () {
    this.clearList()
    this.getPickls()
  }
  resetListing () {
    this.stateId = ''
    this.locationId = ''
    this.storeLocationId = ''
    this.storeName = ''
    this.storeLocations = []
    this.getPickls()
  }

  created () {
    this.brandId = this.$store.state.userData.brandId
    this.getBrand()
    this.getCountries()
    let userData = this.$store.state.userData
    this.userRole = userData.role
    if (this.userRole === 'super-admin') {
      this.userUrl = 'sadmin'
    } else {
      this.userUrl = 'staff'
    }
    this.getBrandLoginHistory()
    this.getStores()
    this.getOffers()
    this.getBrandOffers()
    this.getSurveys()
    this.getNonGeoPicklStatusCount()
  }

  getStatesByCountry () {
    this.updateList()
    if (this.countryId !== '') {
      this.$http.get('states?country_id=' + this.countryId).then((response: any) => {
        this.states = response.body.data
      })
    } else {
      this.states = []
    }
  }

  switchCard (index: number) {
    if (this.picklIndex !== index) {
      this.clearList()
      this.offset = 0
      this.picklIndex = index
      this.getPickls()
    }
  }

  switchNonGeoCard (index: number) {
    if (this.nonGeoPicklIndex !== index) {
      this.clearList()
      this.offset = 0
      this.nonGeoPicklIndex = index
      this.getNonGeoPickls()
    }
  }

  loadMore () {
    this.offset += this.limit
    this.getPickls()
  }

  loginAsBrand () {
    this.$store.commit('showLoading')

    this.$store
      .dispatch('loginAsBrand', { brandId: this.brand.id })
      .then(response => {
        this.$router.push('/brand/dashboard')
      })
      .finally(() => this.$store.commit('hideMessage'))
  }

  picklCreatedSuccessFully () {
  }

  getPicklDetails (id:number) {
    this.selectedPicklId = id
  }

  updatePicklOffset (deletedPicklId: any) {
    this.deletedPickls.push(deletedPicklId)
    this.getBrand()
    this.offset = this.offset - 1
  }

  checkDeletedPIckls (picklId: any) {
    return this.deletedPickls.includes(picklId)
  }

  checkOnboard () {
    let onboardStatus = !this.brand.on_boarded
    this.$store.dispatch('updateOnBoardStatus', {
      id: this.brand.id,
      status: onboardStatus
    }).then(
      (response: any) => {
        this.getBrand()
      },
      response => {}
    )
  }

  checkNonGeoPicklFeature () {
    let onboardStatus = !this.brand.non_geo_pickl
    this.$store.dispatch('updateNonGeoPicklStatus', {
      id: this.brand.id,
      status: onboardStatus
    }).then(
      (response: any) => {
        this.getBrand()
      },
      response => {}
    )
  }

  checkIsAgeRestricted () {
    let status = !this.brand.age_restricted
    this.$store.dispatch('updateBrandAgeRestrictedStatus', {
      id: this.brand.id,
      status: status
    }).then(
      (response: any) => {
        this.getBrand()
      },
      response => {}
    )
  }

  checkSurveyFeature () {
    let status = !this.brand.survey_feature
    this.$store.dispatch('updateBrandSurveyFeatureStatus', {
      id: this.brand.id,
      status: status
    }).then(
      (response: any) => {
        this.getBrand()
      },
      response => {}
    )
  }

  checkDataAccess () {
    let status = !this.brand.dashboard_data_access
    this.$store.dispatch('updateBrandDashboardDataAccess', {
      id: this.brand.id,
      status: status
    }).then(
      (response: any) => {
        this.getBrand()
      },
      response => {}
    )
  }

  checkCouponFeature () {
    let status = !this.brand.coupon_feature
    this.$store.dispatch('updateBrandCouponFeatureStatus', {
      id: this.brand.id,
      status: status
    }).then(
      (response: any) => {
        this.getBrand()
      },
      response => {}
    )
  }
  checkSelfieFeature () {
    let status = !this.brand.selfie_feature
    this.$store.dispatch('updateBrandSelfieFeatureStatus', {
      id: this.brand.id,
      status: status
    }).then(
      (response: any) => {
        this.getBrand()
      },
      response => {}
    )
  }
  checkMultiTaskFeature () {
    let status = !this.brand.multi_pickl_feature
    this.$store.dispatch('updateBrandMultiTaskFeatureStatus', {
      id: this.brand.id,
      status: status
    }).then(
      (response: any) => {
        this.getBrand()
      },
      response => {}
    )
  }
  checkReimbursementFeature () {
    let status = !this.brand.reimbursement_feature
    this.$store.dispatch('updateBrandReimbursementFeatureStatus', {
      id: this.brand.id,
      status: status
    }).then(
      (response: any) => {
        this.getBrand()
      },
      response => {}
    )
  }
  checkLockPicklFeature () {
    let status = !this.brand.lock_pickl_feature
    this.$store.dispatch('updateBrandLockPicklFeatureStatus', {
      id: this.brand.id,
      status: status
    }).then(
      (response: any) => {
        this.getBrand()
      },
      response => {}
    )
  }
  checkDemographicsFiltersFeature () {
    let status = !this.brand.demographics_filters_feature
    this.$store.dispatch('updateBrandDemographicsFiltersFeatureStatus', {
      id: this.brand.id,
      status: status
    }).then(
      (response: any) => {
        this.getBrand()
      },
      response => {}
    )
  }

  @Watch('brand')
  onBrandChanged (value: any, oldValue: any) {
    if (oldValue && value && oldValue.id !== value.id) {
      this.getPicklsCount()
    }
  }

  @Watch('stateId')
  onPropertyChanged (value: string, oldValue: string) {
    let context = this
    clearTimeout(this.timer)
    this.timer = setTimeout(function () {
      context.updateList()
    }, 500 || 0)
  }
}
