

























































import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

@Component
export default class SurveyItem extends Vue {
    @Prop() survey!: any
    redirectToEditSurveyPage (surveyId: any, brandId: any) {
      this.$router.push('/sadmin/brands/' + brandId + '/surveys/' + surveyId + '/edit')
    }
    redirectToSurveyStatsPage (surveyId: any, brandId: any) {
      if (this.$store.state.userData.role === 'super-admin') {
        this.$router.push('/sadmin/brands/' + brandId + '/surveys/' + surveyId + '/stats')
      }
      if (this.$store.state.userData.role === 'staff') {
        this.$router.push('/staff/brands/' + brandId + '/surveys/' + surveyId + '/stats')
      }
    }
}
