

























import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'
import { Brand } from '@/components/SAdmin/BrandItem.vue'

@Component
export default class DeactivateBrandPopup extends Vue {
    @Prop() popupId!: string;
    @Prop() brand!: Brand;
    public submittingForm: boolean = false

    changeStatus () {
      this.submittingForm = true
      let popupCloseButton: HTMLElement = this.$refs.popupCloseButton as HTMLElement
      this.$store.dispatch('cancelBrandSubscription', { brandId: this.brand.id }).then((response) => {
        if (response.response.body.status) {
          this.$notify({ type: 'success', text: response.response.body.message })
        } else {
          this.$notify({ type: 'error', text: response.response.body.message })
        }
        this.$emit('subscriptionCanceled')
        this.submittingForm = false
        popupCloseButton.click()
      }, () => popupCloseButton.click())
    }
}
