


























































































import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'
import DatePicker from 'vue2-datepicker'

@Component({
  components: {
    DatePicker
  }
})
export default class SendBrandPicklNotofication extends Vue {
  @Prop() popupId!: string
  @Prop() brandId!: number
  public time: string = ''
  public type: string = ''
  public picklType: string = ''
  public date: Date = new Date()
  public testRun: boolean = false
  public submittingForm = false
  public submitButtonText = 'SEND NOTIFICATION'

  getPicklStatus () {
    if (this.picklType === 'NON_GEO') {
      this.type = 'PENDING'
    }
  }

  sendAcceptedPicklNotification () {
    this.$validator.validateAll('send-notification').then(result => {
      if (result) {
        this.submitButtonText = 'SENDING NOTIFICATION'
        this.submittingForm = true
        // let popupCloseButton: HTMLElement = this.$refs.popupCloseButton as HTMLElement
        let stringDate
        if (this.date) {
          stringDate = this.date.getFullYear() + '-' + (this.date.getMonth() + 1) + '-' + this.date.getDate()
        }
        let payload = { time: this.time, 'type': this.type, 'brandId': this.brandId, testRun: this.testRun, date: stringDate, picklType: this.picklType }
        this.$store.dispatch('sendNotification', payload).then((response) => {
          this.submittingForm = false
          this.$notify({ text: response.body.message, type: 'success' })
          this.$emit('sendNotification', 'notification sent successfully')
          Object.assign(this.$data, (this.$options.data as any).apply(this))
          this.$nextTick()
            .then(() => {
              this.$validator.errors.clear('send-notification')
            })
          // popupCloseButton.click()
          this.submittingForm = false
          this.submitButtonText = 'NOTIFICATION'
          this.date = new Date()
          this.time = ''
          this.type = ''
          this.testRun = false
        }, response => this.$notify({ text: 'Can not send Notification', type: 'error' }))
      } else if (this.$validator.errors.has('send-notification.time')) {
        this.$notify({ text: this.$validator.errors.first('send-notification.time'), type: 'error' })
      }
    })
  }
}
