







































import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'
import DatePicker from 'vue2-datepicker'
let date = new Date()
@Component({
  components: {
    DatePicker
  }
})

export default class AssignOfferPopup extends Vue {
    @Prop() popupId!: string
    @Prop() brandId!: string
    @Prop() offers!: Object
    public payload = {
      offerId: ''
    }
    public expireDate:Date = new Date(date.setMonth(date.getMonth() + 1))

    assignOffer () {
      this.$validator.validateAll('assign-offer').then((result) => {
        if (result) {
          let popupCloseButton: HTMLElement = this.$refs.popupCloseButton as HTMLElement
          let date = new Date()
          let payload = {
            brandId: this.brandId,
            offerId: this.payload.offerId,
            transactionId: 0,
            startDate: new Date(date.setMonth(date.getMonth())),
            expireDate: this.expireDate,
            offer: {}
          }
          this.$store.dispatch('assignOfferToBrand', payload).then(() => {
            this.$notify({ text: 'Offer Assigned to brand successfully!', type: 'success' })
            Object.assign(this.$data, (this.$options.data as any).apply(this))
            this.$nextTick()
              .then(() => {
                this.$validator.errors.clear('assign-offer')
              })
            this.$emit('offerAssinged')
            popupCloseButton.click()
          }, response => this.$notify({ text: 'Cannot add pickls', type: 'error' }))
        } else if (this.$validator.errors.has('free-pickls.No of Pickls')) {
          this.$notify({ text: this.$validator.errors.first('free-pickls.No of Pickls'), type: 'error' })
        }
      })
    }
}
