




















import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

@Component
export default class UpdateStripeFeePopup extends Vue {
    @Prop() popupId!: string
    @Prop() brandId!: string
    public payload = {
      stripe_fee: ''
    }
    updateStripeFee () {
      this.$validator.validateAll('update-stripe-fee-form').then((result) => {
        if (result) {
          let popupCloseButton: HTMLElement = this.$refs.popupCloseButton as HTMLElement
          this.$store.dispatch('updateBrandStripeFee', { id: this.brandId, data: this.payload }).then(() => {
            this.$notify({ text: `${this.payload.stripe_fee}% set as stripe fee for brand`, type: 'success' })
            Object.assign(this.$data, (this.$options.data as any).apply(this))
            this.$nextTick()
              .then(() => {
                this.$validator.errors.clear('update-stripe-fee-form')
              })
            this.$emit('updated')
            popupCloseButton.click()
          }, response => this.$notify({ text: 'Error while updating stripe fee', type: 'error' }))
        } else if (this.$validator.errors.has('update-stripe-fee-form.stripe-fee')) {
          this.$notify({ text: this.$validator.errors.first('update-stripe-fee-form.stripe-fee'), type: 'error' })
        }
      })
    }
}
